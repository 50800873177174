import Axios from "axios";

const Client = Axios.create({
    baseURL: process.env.VUE_APP_BASE_HOST
})

const ignoreUrl = ['login', 'courier/smsLogin', 'courier/smsCode']

Client.interceptors.request.use(config => {
    // console.log(config)
    if (!ignoreUrl.includes(config.url)) {
        config.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
    }
    return config
}, error => {
    return Promise.reject(error)
})
// Client.interceptors.response.use(response => {
//     return response
// }, error => {
//     return Promise.reject(error)
// })

export default Client
